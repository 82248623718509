const ComeTogether = () => {
  return (
    <div className="ecoBag__comeTogether">
      <img
        className="ecoBag__opacityLogo"
        src="https://decathlonstore.vteximg.com.br/arquivos/picto-eco.png"
        alt="logo eco bags"
      />
    </div>
  )
}

export default ComeTogether
