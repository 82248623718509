const LeveSuaSacola = () => {
  return (
    <div className="ecoBag__wrapper leve-sua-sacola">
      <p className="ecoBag__paragraph">
        Se quisermos um futuro melhor para o nosso planeta, a mudança de
        comportamento de cada um é urgente. Nós entendemos que temos um papel
        fundamental para ajudar a construir um mundo mais sustentável.
      </p>
      <p className="ecoBag__paragraph">
        Iniciamos o movimento{' '}
        <strong className="ecoBag__highlight">#LeveSuaSacola</strong> para
        incentivar você a levar a sua própria sacola para guardar as compras que
        fizer em nossas lojas.
      </p>
      <div className="ecoBag__colorBlock">
        <p className="ecoBag__paragraph white">
          Se podemos fazer a nossa parte para mudar, vamos começar pelas nossas
          próprias ações!
        </p>
        <p className="ecoBag__paragraph white">
          <strong className="ecoBag__strong">
            Afinal, melhor do que reutilizar e reciclar, é reduzir!
          </strong>
        </p>
      </div>
    </div>
  )
}

export default LeveSuaSacola
