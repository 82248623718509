const EcoOptions = () => {
  return (
    <div className="ecoBag__wrapper eco-options">
      <p className="ecoBag__paragraph">
        Contribuir para o planeta é mais fácil do que parece. Se liga nas opções
        para fazer a sua parte:
      </p>
      <ul className="ecoBag__bagsList">
        <li className="ecoBag__bagsList--item">
          <p className="ecoBag__bagsList--title">
            <strong>1ª opção</strong>
          </p>
          <img
            className="ecoBag__icon"
            src="https://decathlonstore.vteximg.com.br/arquivos/ecoBag__option1.png"
            alt="option 1"
          />
          <p className="ecoBag__bagsList--text">
            Levar a sua sacola para nossas lojas
          </p>
        </li>
        <li className="ecoBag__bagsList--item">
          <p className="ecoBag__bagsList--title">
            <strong>2ª opção</strong>
          </p>
          <img
            className="ecoBag__icon"
            src="https://decathlonstore.vteximg.com.br/arquivos/ecoBag__option2.png"
            alt="option 2"
          />
          <p className="ecoBag__bagsList--text">
            Adquirir uma sacola de tecido, mais durável e resistente para
            guardar suas compras e também utilizar no seu dia a dia (ida à
            academia, feiras, parques, praia...)
          </p>
        </li>
        <li className="ecoBag__bagsList--item">
          <p className="ecoBag__bagsList--title">
            <strong>3ª opção</strong>
          </p>
          <img
            className="ecoBag__icon"
            src="https://decathlonstore.vteximg.com.br/arquivos/ecoBag__option1.png"
            alt="option 3"
          />
          <p className="ecoBag__bagsList--text">Adquirir uma sacola de papel</p>
        </li>
      </ul>
    </div>
  )
}

export default EcoOptions
