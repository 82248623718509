const SectionBags = () => {
  return (
    <div className="ecoBag__wrapper eco-bags">
      <img
        className="ecoBag__banner"
        src="https://decathlonstore.vteximg.com.br/arquivos/sacola-desktop.png"
        alt="eco bags"
      />
      <ul className="ecoBag__bagsList">
        <li className="ecoBag__bagsList--item">
          <img
            className="ecoBag__icon"
            src="https://www.decathlon.com.br/arquivos/logoeco2.png"
            alt=""
          />
          <p className="ecoBag__bagsList--text">
            Feita{' '}
            <strong className="ecoBag__bagsList--regular">
              100% em algodão cru,
            </strong>{' '}
            sem tingimento, o que reduz o consumo de energia e água no processo;
          </p>
        </li>

        <li className="ecoBag__bagsList--item">
          <img
            className="ecoBag__icon"
            src="https://www.decathlon.com.br/arquivos/bandeiraicon.png?v=637316405721470000"
            alt=""
          />
          <p className="ecoBag__bagsList--text">
            <strong className="ecoBag__bagsList--regular">
              Produção nacional, para redução nas emissões de CO²
            </strong>{' '}
            atreladas a logística de entrega, em fornecedor 100% auditado;
          </p>
        </li>

        <li className="ecoBag__bagsList--item">
          <img
            className="ecoBag__icon"
            src="https://www.decathlon.com.br/arquivos/icone-tinta.png"
            alt=""
          />
          <p className="ecoBag__bagsList--text">
            Sua impressão é feita com{' '}
            <strong className="ecoBag__bagsList--regular">
              tinta à base de água,
            </strong>{' '}
            que minimiza os impactos ambientais comparados ao uso de tintas à
            base de solvente;
          </p>
        </li>

        <li className="ecoBag__bagsList--item">
          <img
            className="ecoBag__icon"
            src="https://www.decathlon.com.br/arquivos/car-icon.png"
            alt=""
          />
          <p className="ecoBag__bagsList--text">
            <strong className="ecoBag__bagsList--regular">
              Não faz uso de embalagem plástica para o transporte
            </strong>{' '}
            até cada uma de nossas lojas;
          </p>
          <p className="ecoBag__bagsList--text" />
        </li>

        <li className="ecoBag__bagsList--item">
          <img
            className="ecoBag__icon"
            src="https://www.decathlon.com.br/arquivos/sacola-icon.png"
            alt=""
          />
          <p className="ecoBag__bagsList--text">
            Disponível em{' '}
            <strong className="ecoBag__bagsList--regular">
              tamanho único, por R$ 10,99.
            </strong>
          </p>
        </li>
      </ul>
    </div>
  )
}

export default SectionBags
