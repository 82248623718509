const MainBanner = () => {
  return (
    <div className="ecoBag__fullBanner">
      <div className="box-banner">
        <a href="#D">
          <img
            width={100}
            height={100}
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2604407/banner-sacola-mobile.png?v=637447648451970000"
            alt="main banner"
          />
        </a>
      </div>
      <div className="ecoBag__fullBanner--block">
        <span className="ecoBag__fullBanner--text bigger">você</span>
        <span className="ecoBag__fullBanner--text bigger">sabia que...</span>
      </div>
    </div>
  )
}

export default MainBanner
