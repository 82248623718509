import FaqComponentEcoBag from './FaqComponentEcoBag'

const EcoBagFaq = () => {
  return (
    <div className="ecoBag__faq">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="89px"
        height="89px"
      >
        <path
          fillRule="evenodd"
          fill="rgb(99,198,152)"
          d="M44.500,-0.000 C69.077,-0.000 89.000,19.923 89.000,44.500 C89.000,69.077 69.077,89.000 44.500,89.000 C19.923,89.000 0.000,69.077 0.000,44.500 C0.000,19.923 19.923,-0.000 44.500,-0.000 Z"
        />
        <text
          kerning="auto"
          fontFamily="Myriad Pro"
          fill="rgb(0, 0, 0)"
          transform="matrix( 2.14404438310071, 0, 0, 2.14404438310065,33.9425287528957, 66.9404438310066)"
          fontSize="27.629px"
        >
          <tspan
            fontSize="27.629px"
            fontFamily="Roboto Condensed"
            fontWeight="bold"
            fill="#FFFFFF"
          >
            &#63;
          </tspan>
        </text>
      </svg>
      <h2 className="ecoBag__faq--title">Perguntas Frequentes</h2>

      <div className="ecoBag__questions">
        <FaqComponentEcoBag
          title="Quantas sacolas plásticas deixarão de ser usadas por ano, na
              Decathlon Brasil?"
          content="Deixaremos de utilizar 12 milhões de sacolas plásticas por ano, o
              equivalente a 375 toneladas de plástico."
        />
        <FaqComponentEcoBag
          title="Qual o valor da sacola reutilizável para o consumidor?"
          content="<strong>PREÇO:</strong> R$ 10,99"
        />
        <FaqComponentEcoBag
          title="E as embalagens para presente?"
          content="Teremos uma opção para os produtos que podem ser acomodados nas
          sacolas, com uma etiqueta para fechamento. E para os produtos mais
          volumosos, teremos papel de presente. A duas opções serão
          distribuídas de forma gratuita.
          <br />
          <br />A última compra de embalagens plásticas de presente realizadas
          pela Decathlon foi em 2019, porém algumas de nossas lojas ainda
          possuem uma quantidade em estoque. Manteremos a distribuição
          gratuita das sacolas plásticas, até que os estoque se esgotem."
        />
      </div>
    </div>
  )
}

export default EcoBagFaq
