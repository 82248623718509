import Banner from './BannerEcoBags'
import ComeTogether from './ComeTogether'
import EcoBagFaq from './EcoBagFaq'
import EcoBagFooter from './EcoBagFooter'
import EcoBagWrapper from './EcoBagWrapper'
import EcoHeader from './EcoHeader'
import EcoOptions from './EcoOptions'
import EcoPaperBag from './EcoPaperBag'
import EcoSteps from './EcoSterps'
import IntroductionText from './IntroductionText'
import LeveSuaSacola from './LeveSuaSacola'
import MainBanner from './MainBanner'
import SectionBags from './SectionBags'
import './styles.scss'

const EcoSacolas = () => {
  return (
    <>
      <div className="ecoBag">
        <EcoHeader />
        <MainBanner />
        <EcoBagWrapper />
        <LeveSuaSacola />
        <ComeTogether />
        <EcoSteps />
        <EcoOptions />
        <IntroductionText />
        <SectionBags />
        <Banner />
        <EcoPaperBag />
        <EcoBagFaq />
        <EcoBagFooter />
      </div>
    </>
  )
}

export default EcoSacolas
