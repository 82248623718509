const EcoSteps = () => {
  return (
    <div className="ecoBag__wrapper eco-steps">
      <p className="ecoBag__paragraph">
        Uma sacola reutilizável consegue suportar mais peso do que uma sacola de
        plástico. Ela também pode substituir cerca de 125 sacolas de plástico,
        com uma vida estimada entre 12 a 104 utilizações.
      </p>
      <p className="ecoBag__paragraph">
        Além disso, permite a redução no consumo de matéria-prima, que contribui
        diretamente para a preservação do meio ambiente.
      </p>
      <div className="ecoBag__steps">
        <img
          className="ecoBag__steps--image"
          src="https://decathlonstore.vteximg.com.br/arquivos/Layer001.png?v=637321454249970000"
          alt=""
        />
        <img
          className="ecoBag__steps--image"
          src="https://decathlonstore.vteximg.com.br/arquivos/Layer002.png?v=637321454838370000"
          alt=""
        />
        <img
          className="ecoBag__steps--image"
          src="https://decathlonstore.vteximg.com.br/arquivos/Layer003.png?v=637321458182500000"
          alt=""
        />
      </div>
      <p className="ecoBag__paragraph">
        Esqueci a minha sacola em casa, Decathlon. Como faço minhas compras?
      </p>
    </div>
  )
}

export default EcoSteps
