const EcoBagFooter = () => {
  return (
    <div className="ecoBag__footer">
      <div className="ecoBag__grid half grey">
        <div className="ecoBag__flex column">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="84px"
            height="84px"
          >
            <path
              fillRule="evenodd"
              fill="rgb(255, 255, 255)"
              d="M41.859,0.157 C64.909,0.157 83.594,18.842 83.594,41.890 C83.594,64.940 64.909,83.625 41.859,83.625 C18.810,83.625 0.125,64.940 0.125,41.890 C0.125,18.842 18.810,0.157 41.859,0.157 Z"
            />
            <path
              fillRule="evenodd"
              fill="rgb(99,198,152)"
              d="M24.312,35.312 L63.469,35.312 C64.573,35.312 65.469,36.208 65.469,37.312 L65.469,45.000 C65.469,46.104 64.573,47.000 63.469,47.000 L24.312,47.000 C23.208,47.000 22.313,46.104 22.313,45.000 L22.313,37.312 C22.313,36.208 23.208,35.312 24.312,35.312 Z"
            />
          </svg>
          <h4 className="ecoBag__less">Menos impacto ambiental</h4>
          <h4 className="ecoBag__less">Menos consumismo imediato</h4>
          <h4 className="ecoBag__less">Menos desperdício</h4>
        </div>
        <div className="ecoBag__flex column">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="84px"
            height="84px"
          >
            <path
              fillRule="evenodd"
              fill="rgb(255, 255, 255)"
              d="M41.828,0.157 C64.877,0.157 83.563,18.842 83.563,41.890 C83.563,64.940 64.877,83.625 41.828,83.625 C18.779,83.625 0.094,64.940 0.094,41.890 C0.094,18.842 18.779,0.157 41.828,0.157 Z"
            />
            <path
              fillRule="evenodd"
              fill="rgb(99,198,152)"
              d="M61.781,47.000 L48.125,47.000 L48.125,60.687 C48.125,61.792 47.230,62.687 46.125,62.687 L38.438,62.687 C37.333,62.687 36.438,61.792 36.438,60.687 L36.438,47.000 L22.719,47.000 C21.614,47.000 20.719,46.104 20.719,45.000 L20.719,37.312 C20.719,36.208 21.614,35.312 22.719,35.312 L36.438,35.312 L36.438,21.594 C36.438,20.489 37.333,19.593 38.438,19.593 L46.125,19.593 C47.230,19.593 48.125,20.489 48.125,21.594 L48.125,35.312 L61.781,35.312 C62.886,35.312 63.781,36.208 63.781,37.312 L63.781,45.000 C63.781,46.104 62.886,47.000 61.781,47.000 Z"
            />
          </svg>
          <h4 className="ecoBag__more">Mais biodiversidade</h4>
          <h4 className="ecoBag__more">Mais consumo sustentável</h4>
          <h4 className="ecoBag__more">Mais vida</h4>
        </div>
      </div>
      <div className="ecoBag__grid green">
        <div className="ecoBag__flex row">
          <a
            target="_blank"
            rel="noreferrer"
            className="ecoBag__link ecoBag__link--initiative"
            href="http://sustainability.decathlon.com/"
          >
            Entenda mais sobre as nossas iniciativas sustentáveis
          </a>
        </div>
      </div>
    </div>
  )
}

export default EcoBagFooter
