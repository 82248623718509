const EcoBagWrapper = () => {
  return (
    <div className="ecoBag__wrapper">
      <ul className="ecoBag__list">
        <li className="ecoBag__list--item">
          <p className="ecoBag__list--text">
            A sacola plástica leva até{' '}
            <strong className="ecoBag__highlight">400 anos</strong> para se
            degradar?
          </p>
        </li>
        <li className="ecoBag__list--item">
          <p className="ecoBag__list--text">
            No Brasil, são distribuídas cerca de{' '}
            <strong className="ecoBag__highlight">
              1,5 milhão de sacolas plásticas
            </strong>{' '}
            por hora? E apenas 30% desse plástico é descartado de maneira
            responsável, o que aumenta diariamente a poluição de matas e
            oceanos?
          </p>
        </li>
        <li className="ecoBag__list--item">
          <p className="ecoBag__list--text">
            <strong className="ecoBag__highlight">Na Decathlon,</strong> temos
            vários compromissos em respeito ao meio ambiente:
          </p>
        </li>
      </ul>
      <ul className="ecoBag__commitmentList">
        <li className="ecoBag__commitmentList--item">
          <p className="ecoBag__commitmentList--text">
            <strong className="ecoBag__regular">Compromissos climáticos</strong>
          </p>
          <div className="ecobag__video">
            <iframe
              width="100%"
              height="640"
              src="https://www.youtube.com/embed/nKILlC2RmjE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
              title="video eco bag"
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default EcoBagWrapper
