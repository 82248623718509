import { useCallback, useState } from 'react'

interface Props {
  title: string
  content: string
}

const FaqComponentEcoBag = (props: Props) => {
  const [setActive, setActiveState] = useState('active')

  const toggleAccordion = useCallback(() => {
    setActiveState(setActive === '' ? 'active' : '')
  }, [setActive])

  return (
    <div className={`ecoBag__accordion ${setActive}`}>
      <div
        className="ecoBag__accordion--wrap"
        onClick={toggleAccordion}
        onKeyDown={toggleAccordion}
        role="button"
        tabIndex={0}
      >
        <p
          className="ecoBag__accordion--text ecoBag__wrapper js-question-accordion"
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </div>
      <p
        className="ecoBag__accordion--response ecoBag__wrapper"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    </div>
  )
}

export default FaqComponentEcoBag
