const BannerEcoBags = () => {
  return (
    <div className="ecoBag__banner">
      <div className="box-banner">
        <a href="#d">
          <img
            width={100}
            height={100}
            src="https://decathlonpro.vteximg.com.br/arquivos/ids/2604332/banner-sacola-papel.png"
            alt="Eco Sacolas Papel Banner"
          />
        </a>
      </div>
      <h1 className="ecoBag__title bottom">Sacola de papel</h1>
    </div>
  )
}

export default BannerEcoBags
