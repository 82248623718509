const EcoPaperBag = () => {
  return (
    <div className="ecoBag__wrapper">
      <img
        className="ecoBag__icon fsc"
        src="https://www.decathlon.com.br/arquivos/fsc01.png"
        alt=""
      />
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        Tem o selo FSC (Forest Stewardship Council) que provém de madeira de
        reflorestamento;
      </p>
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        É feita de papel craft e não passa pelo processo de branqueamento;
      </p>
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        A impressão do logotipo Decathlon não é feita com solvente;{' '}
      </p>
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        O seu transporte é feito sem embalagem plástica;
      </p>
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        A sua degradação demora em média 3 meses;
      </p>
      <p className="ecoBag__paragraph ecoBag__paragraph--papelBag">
        Disponível nos tamanhos P, M e G.
      </p>
    </div>
  )
}

export default EcoPaperBag
