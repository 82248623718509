const IntroductionText = () => {
  return (
    <div className="ecoBag__wrapper introduction-text">
      <p className="ecoBag__paragraph">
        Agora, em nossas lojas, você poderá contar com alternativas para
        transportar os seus produtos. E, se liga: o preço final da opção da
        sacola reutilizável foi calculado sem fins lucrativos, apenas com o
        intuito de cobrir os custos de produção e logística. Porque aqui, na
        Decathlon, a gente joga sempre junto!
      </p>
      <p className="ecoBag__paragraph">
        Entenda melhor sobre a nossa sacola reutilizável e a de papel.
      </p>
    </div>
  )
}

export default IntroductionText
